<template>
  <section class="mt-5 mb-24 md:mt-10 md:mb-5">
    <div class="container">
      <div class="gradient rounded-[10px] -md:p-3 md:py-48">
        <div class="md:grid grid-cols-12 md:pl-2.5 md:pr-6">
          <div class="col-span-10 col-start-3">
            <div class="md:grid grid-cols-8 border-b border-gray-500 pb-3 md:pb-8">
              <div class="col-span-2">
                <AtomText :content="data.telInfo" />
              </div>
              <div class="col-start-4 col-span-6">
                <a :href="`tel:${data.telUrl}`">
                  <AtomTitle class="-md:pt-3" tag="h2" :content="data.tel" />
                </a>
              </div>
            </div>
            <div class="md:grid grid-cols-8 pt-4 md:pt-8">
              <div class="col-span-2 flex items-center">
                <AtomImage class="w-20 h-20 md:w-14 md:h-14 rounded-full object-cover mr-3" :src="data.ava" />
                <AtomText :content="data.emailInfo" />
              </div>
              <div class="col-start-4 col-span-7">
                <a :href="`mailto:${data.email}`">
                  <AtomTitle class="-md:pt-3" tag="h2" :content="data.email" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AtomImage from '../../atoms/AtomImage'
import AtomText from '../../atoms/AtomText'
import AtomTitle from '../../atoms/AtomTitle'

export default {
  name: 'FranchiseContacts',
  builder: {
    component: 'franchise-contacts',
  },
  components: {
    AtomText,
    AtomTitle,
    AtomImage,
  },
  data: () => {
    return {
      data: {
        telInfo: 'Дополнительную информацию можно уточнить по телефону',
        tel: '+7 499 653 92 93',
        telUrl: '+74996539293',
        emailInfo: 'или по почте <br />у Яны Лютковой',
        email: 'club@encorefitness.ru',
        ava: 'franchise/ava.webp',
      },
    }
  },
}
</script>
